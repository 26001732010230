<template>
  <div id="myChart5" >
    {{getParstackedLineObjData.times}}
  </div>
</template>

<script>
import * as echarts from 'echarts';
require('echarts/theme/roma');//引入主题

export default {
  props:['getParstackedLineObjData'],
  data() {
    return {
      chartLine2: null,
    }
  },
  watch:{
    'getParstackedLineObjData':{
      handler() {
        this.drawLineChart2();
      }
    },
  },
  created() {

  },
  mounted() {
    this.drawLineChart2();
  },
  methods: {
    drawLineChart2() {
      var chartDom = document.getElementById('myChart5');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        grid: {
          top: '8%',
          left: '2%',
          right: '1%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data:this.getParstackedLineObjData.times,
        },
        dataZoom: [
          {
            show: true,
            realtime: false,
            start: 0,
            end: 50
          },
          {
            type: 'inside',
            realtime: false,
            start: 0,
            end: 50
          }
        ],

        yAxis: {
          type: 'value',
          show: true, // 是否显示 Y轴

        },
        color: [ '#44a4ff'],
        series: [
          {
            data:this.getParstackedLineObjData.rates,
            type: 'line',
            showBackground: false,
            itemStyle : { normal: {label : {show: true}}}
          }
        ],
        // 鼠标移上去显示数据
        tooltip : {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        }
      };

      option && myChart.setOption(option);

    }
  }
}
</script>

<style lang='scss' scope>
#myChart5 {
  width: 100%;
  height: 620px;
  padding-bottom: 20px;
  box-sizing: border-box;
}
</style>
