<template>
  <div class="case-conent">
    <div class="hd">个人成长轨迹</div>
    <!--学历案日志列表-->
    <div class="table-content-search">
      <div class="search">
        <div class="fromItem">
          <el-date-picker
                  v-model="ruleForm.beginTime"
                  type="date"
                  :picker-options="pickerOptionsBegin"
                  placeholder="开始时间"
                  format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                  @change="BeginChange"
                  @clear="BeginClear()"
          ></el-date-picker>
        </div>
        <div class="fromItem">
          <el-date-picker
                  v-model="ruleForm.endTime"
                  type="date"
                  :picker-options="pickerOptionsEnd"
                  placeholder="截止时间"
                  format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                  @change="EndChange"
                  @clear="EndClear()"
                  clearable
          ></el-date-picker>
        </div>

        <div class="fromItem">
          <el-select v-model="ruleForm.subjectId" @change="subjectChange" @clear="subjectClear()" clearable placeholder="学科选择">
            <el-option
                    v-for="item in SubjectList.slice(1,30)"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="fromItem">
          <el-button type="primary" size="small" @click="ObjBtn()"
                     style="text-align: left;"><i class="icon-daoru1-copy"></i> 搜索
          </el-button>
        </div>
      </div>
      <div class="table">
        <echarts-e :getParstackedLineObjData="getParstackedLineObjData"></echarts-e>
      </div>

    </div>
  </div>
</template>


<script>
  import moment from 'moment';
  import { mapState } from 'vuex'
  import {getParstackedLineObj} from '@/api/parent'
  import echartsE from "./echarts/echartsE";
  export default {
    name: '',
    data() {
      return {
        clickFlag:null,
        timeD2:'',
        getParstackedLineObjData: {},
        ruleForm:{
          subjectId:'',
          beginTime:'',
          endTime:'',
        },
        listData:[
          {
            sort:1,
            orderNumber:'GR202109300001',
            stuName:'张三',
            rechargeType:'按需充值',
            originalPrice:2356,
            rechargeAmount:1000,
            discount:7,
            rechargeMode:'支付宝',
            state:1,
            time:'2021-10-12 15:38:45'
          }
        ],
        pickerOptionsBegin: {
          disabledDate: (time) => {
            if (this.ruleForm.endTime) {
              return time.getTime() > new Date(this.ruleForm.endTime).getTime()
            }
          },
        },
        pickerOptionsEnd: {
          disabledDate: (time) => {
            if (this.ruleForm.beginTime) {
              return time.getTime() < new Date(this.ruleForm.beginTime).getTime()
            }
          },
        },

      }
    },

    created() {
      this.EducationCase = this.$route.query.EducationCase;
      this.par = this.$route.query.par;
      this.subjectObj()
      this.beginTimeObj()

    },
    computed:{
      ...mapState([
        'SubjectList',
      ]),
      timeDefault() {
        var date = new Date();
        var s1 = date.getFullYear() + "-" + (date.getMonth() - 1) + "-" + (date.getDate()-0);
        return moment(s1).format("YYYY-MM-DD");
      },
      timeEndDefault() {
        var s2 = new Date();
        return moment(s2).format("YYYY-MM-DD");
      },
    },
    mounted() {
      // 初始化查询，默认为前一天
      this.ruleForm.beginTime = this.timeDefault;
      this.ruleForm.endTime = this.timeEndDefault;

      this.listObj()
    },
    methods: {

      subjectObj() {
        this.ruleForm.subjectId = this.SubjectList[2].id
      },
      // 学科
      subjectChange($event){
        this.ruleForm.subjectId = $event
      },
      // 清除学科
      subjectClear() {
        this.ruleForm.subjectId = ''
      },
      beginTimeObj() {

      },
      //开始时间
      BeginChange(val) {
        this.ruleForm.beginTime = val
        sessionStorage.setItem('stuJobAnalysisMasterBeginDate',val)
      },
      EndChange(val) {
        this.ruleForm.endTime = val
        sessionStorage.setItem('stuJobAnalysisMasterEndDate',val)
      },

      //查询筛选的学科列表
      listObj() {
        // this.ruleForm.beginTime = sessionStorage.getItem("beGinTime")
        // this.ruleForm.endTime = sessionStorage.getItem("enDTime")
        let obj = {
          subjectId:this.ruleForm.subjectId,
          beginTime:this.ruleForm.beginTime,
          endTime:this.ruleForm.endTime,
        }
        getParstackedLineObj(obj).then((res) => { // eslint-disable-line no-unused-vars
          if(res.code==200) {
            this.getParstackedLineObjData = res.data
          }
        })
      },
      // 搜索
      ObjBtn() {
        this.listObj()
      },


    },

    watch: {
      // 监视搜索词变化
      $route(){
        this.clickFlag= this.$route.query.clickFlag
      },
      'timeDefault' : {
        handler : function(newVal,oldVal){
          this.ruleForm.beginTime=newVal
        }
      },
      'timeEndDefault' : {
        handler : function(newVal,oldVal){
          this.ruleForm.endTime=newVal
        }
      },

    },



    components: {
      echartsE
    }
  }
</script>
<style lang="scss" scoped>
  .case-conent {
    margin: 20px auto;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 0.9;

    .hd {
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      font-weight: bold;
      background: #37AEFF;
      text-align: center;
      color: #fff;
      opacity: 1;
    }

    .table-content-search {
      width: 96%;
      margin: 10px auto;
      .search {
        width: 100%;
        height: 40px;
        display: flex;
        margin-bottom: 10px;
        justify-content: flex-end;
        .fromItem {
          margin-left: 10px;

        }
      }
      .table {
        height: 60%;
      }
      .page {
        height: 40px;
        background-color: #238bff;
      }
    }

    .pag-box {
      width: 100%;
      padding-top: 10px;
      box-sizing: content-box;
      justify-content: center;
      display: flex;

      .total-box {
        float: left;
        line-height: 32px;
        margin-right: 10px;
        justify-content: flex-end;
        display: flex;

        .el-pagination {
          white-space: nowrap;
          padding: 2px 5px 2px 0px;
          color: #303133;
          font-weight: 700;

          .el-pager li {
            background: #ccc;
            -webkit-box-sizing: border-box;
          }

          .el-pager li .active {
            background-color: #FF6820;
            color: #FFF;
          }
        }


      }

    }
  }
  .dialogOrder {
    width: 100%;
    margin: auto auto 20px auto;
    .dialogBody {
      width: 100%;
      line-height: 30px;
      display: flex;
      .lf {
        width: 50%;
        color: #666;
        text-align: right;
      }
      .rg {
        width: 50%;
        color: #000;
        text-align: left;
      }
    }
  }
  .dialogFooter {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #f2f2f2;
    display: flex;
    justify-content: center;
  }
</style>
